@font-face {
    font-family: SuppyFont;
    src: url(../../fonts/PPSupplyMono-Regular.otf);
}

@font-face {
    font-family: SuppyFont;
    src: url(../../fonts/PPSupplyMono-Ultralight.otf);
}

.footer-main {
    background-color: var(--second-bg-color);
    font-family: 'Lexend Deca', sans-serif;
    font-style: normal;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: -1em;

    .contact {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: .5em auto;

        .footer-logo {
            display: flex;
            align-items: center;
            gap: 1em;

            img {
                height: 30px;
                width: 30px;
            }

            .logo-text {
                font-weight: 700;
                font-size: 1.25em;
                line-height: 32px;
                color: var(--text-active);
                white-space: nowrap;
            }
        }

        .society {
            display: flex;
            align-items: center;
            gap: 1em;
            font-weight: 400;
            font-size: .8em;
            line-height: 18px;
            color: var(--icon-color);

            .info:first-child {
                border-right: 1px solid var(--icon-color);
                padding-right: 1em;
            }

            .info:last-child {
                display: flex;
                text-align: center;
                align-items: center;
                gap: .5em;

                .icon {
                    padding: .1em .4em;
                    border-radius: 50%;
                    border: 1px solid var(--icon-color);
                    transition: all .3s ease-in-out;
                    cursor: pointer;
                }

                .icon:hover {
                    background-color: var(--icon-color);
                    color: var(--second-bg-color);
                }
            }
        }

    }

    .contact:first-child {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        padding-bottom: .5em;
    }

    .contact:last-child {
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        .info-contact {
            font-weight: 400;
            font-size: .8em;
            color: var(--text-black-2);

            .info {
                display: flex;
                gap: 1em;
            }
        }

        .info-contact:first-child {
            // font-weight: 600;
            color: var(--text-black);
        }

        .info-contact:nth-child(2) {
            // width: 20em;
        }
    }
}

@media (max-width: 1920px) {
    .footer-main {
        .contact {
            width: 1600px;

            .footer-logo {
                img {
                    height: 3em;
                    width: 9em;
                }

                .logo-text {}
            }

            .society {
                font-size: 1em;

                .info:first-child {}

                .info:last-child {
                    .icon {
                        padding: .4em .5em;
                    }

                    .icon:hover {}
                }
            }

        }

        .contact:first-child {}

        .contact:last-child {

            .info-contact {
                font-size: 1em;
            }

            .info-contact:first-child {}

            .info-contact:nth-child(2) {}
        }
    }
}

@media (max-width: 1720px) {
    .footer-main {
        .contact {
            width: 1400px;

            .footer-logo {
                img {
                    height: 3em;
                    width: 9em;
                    object-fit: cover;
                }

                .logo-text {}
            }

            .society {
                font-size: .9em;

                .info:first-child {}

                .info:last-child {
                    .icon {
                        padding: .2em .4em;
                    }

                    .icon:hover {}
                }
            }

        }

        .contact:first-child {}

        .contact:last-child {

            .info-contact {
                font-size: .9em;
            }

            .info-contact:first-child {}

            .info-contact:nth-child(2) {}
        }
    }

}

@media (max-width: 1440px) {
    .footer-main {
        .contact {
            width: 1200px;

            .footer-logo {
                img {
                    height: 3em;
                    width: 9em;
                }

                .logo-text {}
            }

            .society {
                font-size: .9em;

                .info:first-child {}

                .info:last-child {
                    .icon {
                        padding: .2em .4em;
                    }

                    .icon:hover {}
                }
            }

        }

        .contact:first-child {}

        .contact:last-child {

            .info-contact {
                font-size: .9em;
            }

            .info-contact:first-child {}

            .info-contact:nth-child(2) {}
        }
    }
}

@media (max-width: 1280px) {
    .footer-main {
        .contact {
            width: 980px;
            margin: 1em auto;

            .footer-logo {
                img {
                    height: 2.5em;
                    width: 8em;
                }

                .logo-text {}
            }

            .society {
                font-size: .8em;

                .info:first-child {}

                .info:last-child {
                    .icon {
                        padding: .1em .4em;
                    }

                    .icon:hover {}
                }
            }

        }

        .contact:first-child {}

        .contact:last-child {

            .info-contact {
                font-size: .8em;
            }

            .info-contact:first-child {}

            .info-contact:nth-child(2) {}
        }
    }


}

@media (max-width: 1000px) {
    .footer-main {
        .contact {
            width: 780px;
            margin: .25em auto;

            .footer-logo {
                img {
                    height: 2.5em;
                    width: 8em;
                }

                .logo-text {}
            }

            .society {
                font-size: .8em;

                .info:first-child {}

                .info:last-child {
                    .icon {
                        padding: .1em .4em;
                    }

                    .icon:hover {}
                }
            }

        }

        .contact:first-child {}

        .contact:last-child {

            .info-contact {
                font-size: .8em;
            }

            .info-contact:first-child {}

            .info-contact:nth-child(2) {}
        }
    }
}

@media (max-width: 800px) {
    .footer-main {
        .contact {
            width: 580px;
            margin: .25em auto;

            .footer-logo {
                img {
                    height: 2.5em;
                    width: 8em;
                }

                .logo-text {}
            }

            .society {
                font-size: .8em;

                .info:first-child {}

                .info:last-child {
                    .icon {
                        padding: .1em .4em;
                    }

                    .icon:hover {}
                }
            }

        }

        .contact:first-child {}

        .contact:last-child {

            .info-contact {
                font-size: .8em;
            }

            .info-contact:first-child {}

            .info-contact:nth-child(2) {
                // width: 30em;
            }
        }
    }

}

@media (max-width: 600px) {
    .footer-main {
        .contact {
            width: 380px;

            .footer-logo {
                img {
                    // height: 35px;
                    // width: 100%;
                }

                .logo-text {}
            }

            .society {
                font-size: .8em;

                .info:first-child {}

                .info:last-child {
                    .icon {
                        padding: .1em .4em;
                    }

                    .icon:hover {}
                }
            }

        }

        .contact:first-child {
            .info-contact {
                font-size: .7em;
            }
        }

        .contact:last-child {
            gap: .5em;

            .info-contact {
                font-size: .6em;
            }

            .info-contact:first-child {}

            .info-contact:nth-child(2) {
                // width: 30em;
            }
        }
    }

}

@media (max-width: 400px) {
    .footer-main {
        margin-top: -2em;

        .contact {
            width: 100%;
            margin: .5em;

            .footer-logo {

                img {
                    height: 2.5em;
                    width: 7em;
                }

                .logo-text {}
            }

            .society {
                font-size: .7em;
                gap: .5em;
                margin-right: 1em;

                .info:first-child {
                    padding-right: .5em;
                }

                .info:last-child {
                    .icon {
                        padding: .1em .4em;
                    }

                    .icon:hover {}
                }
            }

        }

        .contact:first-child {
            gap: .5em;

            .info-contact {
                font-size: .8em;

            }
        }

        .contact:last-child {
            gap: .5em;

            .info-contact {
                font-size: .6em;
                display: flex;
                flex-direction: column;
                gap: 1em;
            }

            .info-contact:first-child {}

            .info-contact:nth-child(2) {
                // width: 30em;
                margin-right: 2em;

                .info {}
            }

            .info-contact:nth-child(3) {
                margin-right: 1em;

            }
        }
    }
}