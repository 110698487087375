@import '~antd/dist/antd.css';

@font-face {
    font-family: SuppyFont;
    src: url(../../fonts/PPSupplyMono-Regular.otf);
}

@font-face {
    font-family: SuppyFont;
    src: url(../../fonts/PPSupplyMono-Ultralight.otf);
}

.main-header {
    font-family: "Google Sans Display", "Heebo", Arial, sans-serif;
    // font-family: 'Apercu', sans-serif;
    font-style: normal;
    position: fixed !important;
    width: 100%;
    background: var(--second-bg-color);
    z-index: 1000;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
    gap: 1em;

    .header-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;

        .header-left {
            display: flex;
            align-items: center;

            .header-logo {
                display: flex;
                align-items: center;
                gap: 1em;
                font-family: SuppyFont;
                cursor: pointer;

                img {}

                .logo-text {
                    font-weight: 700;
                    font-size: 1.25em;
                    color: var(--text-active);
                    white-space: nowrap;
                    margin-top: 10px;
                }
            }
        }

        .header-content-input {
            position: relative;
            display: flex;
            align-items: center;
            margin: 0px 2em;

            .search-input {
                width: 40vw;
                font-weight: 400;
                font-size: 1em;
                color: var(--main-color);
                background: var(--second-bg-color);
                border: 1px solid var(--border-input);
                border-radius: 10px;
                padding-left: 2em;
            }

            .search-input::placeholder {
                color: var(--placeholder-text-input);
                font-size: .8em;
            }

            .search-input:hover {
                border: 1px solid var(--main-color) !important;
            }

            .search-input:focus {
                border: 1px solid var(--main-color) !important;
                box-shadow: 0 0 0 1px var(--main-color);
                caret-color: var(--main-color);
            }

            // .anticon {
            //     position: absolute;
            //     padding: .5em;
            //     pointer-events: none;
            //     font-size: 1em;
            //     color: var(--icon-color-placeholder);
            //     cursor: pointer;
            // }

            // .anticon-search {
            //     left: 0;
            // }

            // .anticon-down {
            //     right: 0;
            // }
            .ant-select {
                width: 25em;

                .ant-select-selector {
                    border-radius: 30px;
                    z-index: 3;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 24px;
                    color: #696969;

                    .ant-select-selection-search {
                        .ant-select-selection-search-input {
                            // margin-left: 10px;
                        }
                    }
                }

                .ant-select-selector:hover {
                    outline: none;
                    border: 1px solid var(--main-color);
                }

                .ant-select-selector:focus {
                    outline: none !important;
                    border: 1px solid var(--main-color) !important;
                    z-index: 3;
                    box-shadow: 0 0 0 1px var(--header-color) !important;
                    caret-color: var(--header-color) !important;

                }

                .ant-select-arrow {
                    color: #4E4E4E !important;
                    z-index: 3;
                }

                .ant-select-clear {
                    color: #4E4E4E !important;
                    z-index: 3;
                    right: 35px;
                    top: 45%;
                    width: 7px;
                    height: 7px;


                }

                .ant-select-clear:hover {
                    opacity: .5;
                }
            }
        }

        .header-right {
            display: flex;
            align-items: center;
            gap: .5em;

            .menubtn {
                background: linear-gradient(308.85deg, var(--main-color) 0%, var(--main-color) 100%);
                border: none;
                color: white;
                transition: all 0.3s ease-in-out;
            }

            .menubtn:hover,
            .menubtn:focus {
                animation: pulse 1s;
                box-shadow: 0 0 0 2em transparent;
                background: linear-gradient(308.85deg, var(--main-color) 0%, var(--main-color) 100%);
            }


            .user-infor {
                display: flex;
                // justify-content: space-around;
                gap: 2em;

                .icon-group {
                    display: flex;
                    align-items: center;
                    gap: 2em;
                    cursor: pointer;

                    .anticon {
                        pointer-events: none;
                        font-size: 1.25em;
                        color: var(--icon-color);
                        cursor: pointer;
                    }

                    .anticon.active {
                        color: var(--main-color);
                    }


                }
            }
        }
    }

    .header-type-news {
        display: flex;
        align-items: center;
        margin: 0 auto;
        gap: 2em;

        .content {
            font-weight: 400;
            font-size: 1.25em;
            text-align: center;
            color: var(--text-small);
            transition: all 0.3s ease-in-out;
            border-bottom: 3px solid white;
            cursor: pointer;

        }

        .content.active {
            border-bottom: 3px solid var(--main-color);
            color: var(--main-color);
        }
    }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    outline: none !important;
    border: 1px solid var(--main-color) !important;
    box-shadow: 0 0 0 1px var(--header-color);
    caret-color: var(--header-color);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--main-color) !important;
    color: #FFFFFF;
}

.ant-select-item {
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    color: #696969;
}

.ant-select-item:hover {
    background-color: var(--header-color);
    color: #FFFFFF;
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
    border-radius: 15px;
    padding: 0;
    background: #EEF2F6;

}

.ant-select-item-option-disabled {
    background: #DFDFDF;
    color: #C0C0C0;
}

.ant-select-item-option-disabled:hover {
    background-color: #DFDFDF;
    color: #C0C0C0;
}

.main-header.hide {

    .header-left {
        .header-logo {
            img {}

            .logo-text {}
        }
    }

    .header-content-input {
        .search-input {}

        .search-input::placeholder {}

        .search-input:hover {}

        .search-input:focus {}

        .anticon {}

        .anticon-search {}

        .anticon-down {}


    }

    .header-right {
        .menubtn {}

        .menubtn:hover,
        .menubtn:focus {}


        .user-infor {
            .icon-group {
                .anticon {}

                .anticon.active {}


            }
        }
    }

    .header-type-news {
        .content {}

        .content.active {}
    }
}

.search-active {
    position: fixed;
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 0px 1em;
    width: 100%;
    top: 0;
    z-index: 1000;
    background-color: var(--second-bg-color);

    .search-input-content {
        position: relative;
        display: flex;
        align-items: center;
        margin: .5em;
        width: 100%;

        .search-input {
            font-weight: 400;
            font-size: 1em;
            line-height: 24px;
            color: var(--main-color);
            background: var(--second-bg-color);
            border: 1px solid var(--border-input);
            padding-left: 2em;
            border-radius: 10px;
        }

        .search-input::placeholder {
            color: var(--placeholder-text-input);
            font-size: .8em;
        }

        .search-input:hover {
            border: 1px solid var(--main-color) !important;
        }

        .search-input:focus {
            border: 1px solid var(--main-color) !important;
            box-shadow: 0 0 0 1px var(--main-color);
            caret-color: var(--main-color);
        }

        // .anticon {
        //     position: absolute;
        //     padding: .5em;
        //     pointer-events: none;
        //     font-size: 1em;
        //     color: var(--icon-color-placeholder);
        // }


        // .anticon-search {
        //     left: 0;
        // }

        // .anticon-down {
        //     right: 0;
        // }

        .ant-select {
            width: 100%;

            .ant-select-selector {
                border-radius: 10px;
                z-index: 3;
                font-weight: 500;
                font-size: 12px;
                line-height: 24px;
                color: #696969;

                .ant-select-selection-search {
                    .ant-select-selection-search-input {
                        // margin-left: 10px;
                    }
                }
            }

            .ant-select-selector:hover {
                outline: none;
                border: 1px solid var(--main-color);
            }

            .ant-select-selector:focus {
                outline: none !important;
                border: 1px solid var(--main-color) !important;
                z-index: 3;
                box-shadow: 0 0 0 1px var(--header-color) !important;
                caret-color: var(--header-color) !important;

            }

            .ant-select-arrow {
                color: #4E4E4E !important;
                z-index: 3;
            }

            .ant-select-clear {
                color: #4E4E4E !important;
                z-index: 3;
                right: 35px;
                top: 45%;

            }

            .ant-select-clear:hover {
                opacity: .5;
            }
        }
    }

    .anticon-arrow-left {
        cursor: pointer;
    }
}

@media (max-width: 3000px) {
    .menubtn {
        display: none;
    }
}

@media (max-width: 2560px) {
    .main-header {

        .header-left {
            .header-logo {
                .logo-text {
                    font-size: 2em;
                }
            }
        }

        .header-content-input {
            .search-input {
                width: 50vw;
                font-size: 2em;
            }

            .search-input::placeholder {}

            .search-input:hover {}

            .search-input:focus {}

            .anticon {
                font-size: 2em;
            }

            .anticon-search {}

            .anticon-down {}
        }

        .header-right {
            .menubtn {}

            .menubtn:hover,
            .menubtn:focus {}


            .user-infor {
                .icon-group {
                    .anticon {
                        font-size: 2em;
                    }
                }
            }
        }
    }
}

@media (max-width: 1920px) {
    .main-header {
        .header-info {
            margin-top: 1em;
            width: 1600px;

            .header-left {
                .header-logo {
                    .ant-image {
                        height: 5em;
                        width: 13em;
                    }

                    .logo-text {
                        font-size: 2em;
                        margin-bottom: .3em;
                    }
                }
            }

            .header-content-input {
                .search-input {
                    width: 50vw;
                    font-size: 1.25em;
                    padding-left: 3em;
                }

                .search-input::placeholder {}

                .search-input:hover {}

                .search-input:focus {}

                .anticon {
                    font-size: 1.5em;
                }

                .anticon-search {}

                .anticon-down {}

                .ant-select {
                    width: 50vw;

                    .ant-select-selector {
                        height: 3em;
                        display: flex;
                        align-items: center;
                        font-size: 1.25em;
                        border-radius: 10px;

                        .ant-select-selection-search {
                            display: flex;
                            align-items: center;

                            .ant-select-selection-search-input {
                                // margin-left: 10px;
                            }
                        }
                    }

                    .ant-select-clear {
                        right: 45px;


                    }
                }
            }

            .header-right {
                .menubtn {}

                .menubtn:hover,
                .menubtn:focus {}


                .user-infor {
                    .icon-group {
                        .anticon {
                            font-size: 1.5em;
                        }
                    }
                }
            }
        }

        hr {
            margin: .75em 0;
        }

        .header-type-news {
            width: 1600px;

            .content {
                margin-top: 0;
                font-size: 1.5em;
            }

            .content.active {}
        }
    }

    .ant-select-item {
        font-weight: 500;
        font-size: 1.25em;
        line-height: 2em;
        color: #696969;
    }
}

@media (max-width: 1720px) {
    .main-header {
        .header-info {
            width: 1400px;

            .header-left {
                .header-logo {
                    .ant-image {
                        height: 4.5em;
                        width: 12em;
                    }

                    .logo-text {
                        font-size: 1.5em;
                    }
                }
            }

            .header-content-input {
                .search-input {
                    width: 50vw;
                    font-size: 1.25em;
                    padding-left: 3em;
                }

                .search-input::placeholder {}

                .search-input:hover {}

                .search-input:focus {}

                .anticon {
                    font-size: 1.5em;
                }

                .anticon-search {}

                .anticon-down {}

                .ant-select {
                    width: 40vw;

                    .ant-select-selector {
                        height: 2.75em;
                        font-size: 1.25em;

                        .ant-select-selection-search {
                            .ant-select-selection-search-input {
                                // margin-left: 10px;
                            }
                        }
                    }

                    .ant-select-clear {
                        right: 45px;
                    }
                }
            }

            .header-right {
                .menubtn {}

                .menubtn:hover,
                .menubtn:focus {}


                .user-infor {
                    .icon-group {
                        .anticon {
                            font-size: 1.5em;
                        }
                    }
                }
            }
        }


        .header-type-news {
            width: 1400px;

            .content {
                font-size: 1.5em;
            }

            .content.active {}
        }
    }
}

@media (max-width: 1440px) {
    .main-header {
        .header-info {
            width: 1200px;

            .header-left {
                .header-logo {
                    .ant-image {
                        height: 4em;
                        width: 11em;
                    }

                    .logo-text {
                        font-size: 1.5em;
                    }
                }
            }

            .header-content-input {
                .search-input {
                    width: 40vw;
                    font-size: 1.25em;
                }

                .search-input::placeholder {}

                .search-input:hover {}

                .search-input:focus {}

                .anticon {
                    font-size: 1.5em;
                }

                .anticon-search {}

                .anticon-down {}

                .ant-select {
                    width: 40vw;

                    .ant-select-selector {
                        height: 2.5em;
                        font-size: 1.1em;

                        .ant-select-selection-search {
                            .ant-select-selection-search-input {
                                // margin-left: 10px;
                            }
                        }
                    }

                    .ant-select-clear {
                        right: 45px;

                        .anticon {
                            font-size: 1.1em;
                        }
                    }
                }
            }

            .header-right {
                .menubtn {}

                .menubtn:hover,
                .menubtn:focus {}


                .user-infor {
                    .icon-group {
                        .anticon {
                            font-size: 1.25em;
                        }
                    }
                }
            }
        }

        .header-type-news {
            width: 1200px;

            .content {
                font-size: 1.3em;
            }

            .content.active {}
        }
    }

    .ant-select-item {
        font-weight: 500;
        font-size: 1.1em;
        line-height: 2em;
        color: #696969;
    }
}

@media (max-width: 1280px) {
    .main-header {
        .header-info {
            width: 980px;

            .header-left {
                .header-logo {
                    .ant-image {
                        height: 3.5em;
                        width: 10em;
                    }

                    .logo-text {
                        font-size: 1.5em;
                    }
                }
            }

            .header-content-input {
                .search-input {
                    width: 40vw;
                    font-size: 1em;
                    padding-left: 2.5em;
                }

                .search-input::placeholder {
                    font-size: .8em;
                }

                .search-input:hover {}

                .search-input:focus {}

                .anticon {
                    font-size: 1.25em;
                }

                .anticon-search {}

                .anticon-down {}

                .ant-select {
                    width: 40vw;

                    .ant-select-selector {
                        height: 2.5em;
                        font-size: 1em;

                        .ant-select-selection-search {
                            .ant-select-selection-search-input {
                                // margin-left: 10px;
                            }
                        }
                    }

                    .ant-select-clear {
                        right: 45px;

                        .anticon {
                            font-size: 1.1em;
                        }
                    }
                }
            }

            .header-right {
                .menubtn {}

                .menubtn:hover,
                .menubtn:focus {}


                .user-infor {
                    .icon-group {
                        .anticon {
                            font-size: 1.5em;
                        }
                    }
                }
            }
        }

        .header-type-news {
            width: 980px;

            .content {
                font-size: 1.2em;
            }

            .content.active {}
        }
    }

    .ant-select-item {
        font-weight: 500;
        font-size: 1em;
        line-height: 2em;
        color: #696969;
    }
}

@media (max-width: 1000px) {
    .main-header {
        .header-info {
            width: 780px;

            .header-left {
                .header-logo {
                    .ant-image {
                        height: 3em;
                        width: 9em;
                    }

                    .logo-text {}
                }
            }

            .header-content-input {
                .search-input {
                    width: 35vw;
                    font-size: 1em;
                }

                .search-input::placeholder {
                    font-size: .8em;
                }

                .search-input:hover {}

                .search-input:focus {}

                .anticon {
                    font-size: 1.25em;
                }

                .anticon-search {}

                .anticon-down {}

                .ant-select {
                    width: 40vw;

                    .ant-select-selector {
                        height: 2.5em;
                        font-size: .9em;

                        .ant-select-selection-search {
                            .ant-select-selection-search-input {
                                // margin-left: 10px;
                            }
                        }
                    }

                    .ant-select-clear {
                        right: 35px;
                        top: 11px;

                        .anticon {
                            font-size: 1em;
                        }
                    }
                }
            }

            .header-right {
                .menubtn {}

                .menubtn:hover,
                .menubtn:focus {}


                .user-infor {
                    .icon-group {
                        .anticon {
                            font-size: 1.25em;
                        }
                    }
                }
            }
        }

        .header-type-news {
            width: 780px;
            margin-top: -.25em;

            .content {
                font-size: 1.2em;
            }

            .content.active {}
        }
    }

    .ant-select-item {
        font-weight: 500;
        font-size: .9em;
        line-height: 2em;
        color: #696969;
    }
}

@media (max-width: 800px) {
    .main-header {
        .header-info {
            width: 580px;
            margin-top: .5em;

            .header-left {
                .header-logo {
                    .ant-image {
                        height: 3em;
                        width: 8em;
                    }

                    .logo-text {}
                }
            }

            .header-content-input {
                .search-input {
                    width: 35vw;
                    font-size: 1em;
                }

                .search-input::placeholder {
                    font-size: .8em;
                }

                .search-input:hover {}

                .search-input:focus {}

                .anticon {
                    font-size: 1.25em;
                }

                .anticon-search {}

                .anticon-down {}
            }

            .header-right {
                .menubtn {}

                .menubtn:hover,
                .menubtn:focus {}


                .user-infor {
                    .icon-group {
                        .anticon {}
                    }
                }
            }
        }

        .header-type-news {
            width: 580px;

            .content {
                font-size: 1.1em;
            }

            .content.active {}
        }
    }
}

@media (max-width: 600px) {
    .main-header {
        .header-info {
            width: 380px;
            margin-top: .75em;

            .header-left {
                .header-logo {
                    .logo-text {}
                }
            }

            .header-content-input {
                display: none;

                .search-input {}

                .search-input::placeholder {}

                .search-input:hover {}

                .search-input:focus {}

                .anticon {}

                .anticon-search {}

                .anticon-down {}
            }

            .header-right {
                margin-right: 2.5em;

                .menubtn {
                    display: block;
                    position: absolute;
                    right: .5em;
                    transition: all 0.3s ease-in-out;
                }

                .menubtn:hover,
                .menubtn:focus {}


                .user-infor {
                    .icon-group {
                        .anticon {}
                    }
                }
            }
        }

        .header-type-news {
            width: 380px;

            .content {
                font-size: 1em;
                white-space: nowrap;
            }
        }
    }
}

@media (max-width: 400px) {
    .main-header {
        padding: 0 1em;
        gap: 0em;

        .header-info {
            width: 100%;

            .header-left {


                .header-logo {
                    img {
                        height: 100%;
                        width: 100%;
                    }

                    .logo-text {}
                }
            }

            .header-content-input {
                display: none;

                .search-input {}

                .search-input::placeholder {}

                .search-input:hover {}

                .search-input:focus {}

                .anticon {}

                .anticon-search {}

                .anticon-down {}
            }

            .header-right {
                margin-right: .5em;

                .menubtn {
                    display: block;
                    position: static;
                    right: .5em;
                }

                .menubtn:hover,
                .menubtn:focus {}


                .user-infor {
                    .icon-group {
                        margin-right: 1em;
                        gap: 1em;

                        .anticon {}
                    }
                }
            }
        }

        .header-type-news {
            margin-top: .25em;

            .content {}
        }

    }

    .ant-drawer {
        .ant-drawer-content-wrapper {
            width: 50vw !important;
        }

        .logo-text {
            display: none;
        }
    }
}

// @media (max-width: 300px) {
//     .main-header {
//         padding: .25em 1em;

//         .header-info {
//             .header-left {
//                 .header-logo {
//                     .logo-text {}
//                 }
//             }

//             .header-content-input {
//                 display: none;

//                 .search-input {}

//                 .search-input::placeholder {}

//                 .search-input:hover {}

//                 .search-input:focus {}

//                 .anticon {}

//                 .anticon-search {}

//                 .anticon-down {}
//             }

//             .header-right {
//                 .menubtn {}

//                 .menubtn:hover,
//                 .menubtn:focus {}


//                 .user-infor {
//                     display: none;

//                     .icon-group {
//                         .anticon {}
//                     }
//                 }
//             }
//         }
//     }

//     .ant-drawer {
//         .ant-drawer-content-wrapper {
//             width: 50vw !important;
//         }

//         .logo-text {
//             display: none;
//         }

//         .ant-drawer-body {
//             .header-content-input {
//                 .anticon-down {
//                     display: none;
//                 }
//             }
//         }
//     }
// }