@import 'antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&display=swap');
// @import url('https://fonts.cdnfonts.com/css/apercu');
@import "/src/theme/color.css";
@import "swiper/swiper-bundle.min.css";
@import "swiper/swiper.min.css";

// @font-face {
//     font-family: ;
//     src: url(../../fonts/PPSupplyMono-Regular.otf);
// }

// @import '~antd/dist/antd.sass';
html,
body {
    height: 100%;
    overflow-x: hidden !important;
    background-color: var(--main-bg-color) !important;
    scroll-behavior: smooth;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none !important;
}

.ant-checkbox-inner {
    border-radius: 5px;
    margin: 0px;
}

.ant-checkbox:hover .ant-checkbox-inner {
    border-color: var(--text-active) !important;
}

.ant-checkbox:focus .ant-checkbox-inner {
    border-color: var(--text-active) !important;
    background-color: var(--text-active) !important;
    box-shadow: 0 0 0 1px var(--text-active);
    caret-color: var(--text-active);
}

.ant-checkbox-checked .ant-checkbox-inner {
    border-color: var(--text-active) !important;
    background-color: var(--text-active) !important;
}

.ant-checkbox-checked:hover {
    border-color: red !important;
}

.ant-checkbox-checked::after {
    border: none !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: var(--text-active);
    box-shadow: 0 0 0 1px var(--text-active);
    caret-color: var(--text-active);
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--text-active) !important;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
    color: var(--text-active);
    background-color: var(--background-input);
}

.ant-btn {
    color: #fff;
    border-color: var(--text-active);
    background: var(--text-active);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn:hover {
    opacity: 0.7;
    color: #fff;
    border-color: var(--text-active);
    background: var(--text-active);
}

.atn-btn:active {
    border-color: var(--text-active);
    background: var(--text-active);
}

.ant-table-tbody>tr>td {
    border: none;
}

.ant-table-pagination-right {
    justify-content: center;
}

.ant-dropdown-trigger>.anticon.anticon-down,
.ant-dropdown-link>.anticon.anticon-down,
.ant-dropdown-button>.anticon.anticon-down {
    color: black;
}

.ant-breadcrumb {
    margin-top: 2em;
}

.ant-layout {
    background-color: var(--main-bg-color) !important;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.ant-modal-close-x {
    margin-top: -10px;
    margin-right: 5px;
    height: auto;
    width: auto;
}