:root {
    --main-color: #333333;
    --second-color: #EDFAF2;
    --main-bg-color: #f5f5f6;
    --second-bg-color: #ffffff;
    --header-color: #444444;
    --facebook-color: #446fcd;
    --youtube-color: #ff0000;

    --text-active: #333333;
    --text-black: #333333;
    --text-black-2: #444444;
    --text-white: #ffffff;
    --text-small: #989CA2;
    --icon-color-placeholder: #989CA2;
    --icon-color: #5D5F63;
    --text-label: #5D5F63;
    --icon-background: #5D5F63;

    --background-input: #5D5F63;
    --border-input: #C3C6C9;
    --border-input-hover: ;
    --border-input-focus: ;
    --placeholder-text-input: #C3C6C9;
    --text-color-input: ;
}